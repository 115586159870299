import { openNewProjectDialog } from '@paid-ui/blocks/global-flyout';
import { Button, type NativeButtonProps } from '@paid-ui/components/button';
import { PlusIcon } from '@paid-ui/icons/plus';
import { userManager } from '@paid-ui/models/user';
import { forwardRef } from 'react';
import { useSnapshot } from 'valtio/react';

export const NewProjectButton = forwardRef<HTMLButtonElement, NativeButtonProps>(
  ({ hidden, ...restProps }, ref) => {
    const { disableContractActions } = useSnapshot(userManager);

    if (hidden || disableContractActions) {
      return null;
    }

    return (
      <Button ref={ref} variant="solid" full={false} onClick={openNewProjectDialog} {...restProps}>
        <span>New project</span>
        <PlusIcon />
      </Button>
    );
  },
);

NewProjectButton.displayName = 'NewProjectButton';
